import React from "react";
import { TransitionMotion, spring } from "react-motion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeSystemMessage } from "../../redux/systemMessage/actions";
import styles from "./SystemMessageController.module.scss";

const noop = () => {};

// ------------------------- CONFIG ------------------------

// ----------------------- COMPONENT -----------------------

const Message = ({
  children,
  onClickRemove = noop,
  contentStyle = {},
  title = null,
  ...rest
}) => (
  <div className={styles.message} {...rest}>
    <div className={styles.messageX} onClick={onClickRemove}>
      X
    </div>
    <div className={styles.messageContent} style={contentStyle}>
      {title && <div className={styles.messageTitle}>{title}</div>}
      {children}
    </div>
  </div>
);

const mapStateToProps = ({ systemMessage: { messages } }) => ({
  messages,
});
const mapDispatchToProps = dispatch => ({
  removeSystemMessage: bindActionCreators(removeSystemMessage, dispatch),
});
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class SystemMessageController extends React.Component {
  messageWillEnterStyles = () => {
    return { mult: 0 };
  }
  messageWillLeaveStyles = () => {
    return { mult: spring(0) };
  }
  messageGetStyle = () => {
    return { mult: spring(1) };
  }
  getStyles = () => {
    const { messages } = this.props;
    const ret = messages
      ? messages
          .toArray()
          .reverse()
          .map(({ timestamp, ...rest }) => ({
            key: timestamp,
            style: this.messageGetStyle(),
            data: { ...rest },
          }))
      : [];
    return ret;
  }
  removeMessage = timestamp => () => {
    this.props.removeSystemMessage(timestamp);
  }
  render() {
    if (!this.props.messages) { return null; }
    const computedStyles = this.getStyles();
    if (computedStyles.length === 0) { return null; }
    return (
      <TransitionMotion
        willEnter={this.messageWillEnterStyles}
        willLeave={this.messageWillLeaveStyles}
        styles={computedStyles}
      >
        {interpStyles => (
          <div className={styles.messages}>
            {interpStyles.map(style => {
              const { mult } = style.style;
              return (
                <Message
                  key={style.key}
                  style={{
                    height: mult * 100 + "px",
                    opacity: mult,
                    margin: mult < 0.2 ? 0 : "0.5rem",
                    padding: mult < 0.2 ? 0 : "0.5rem",
                  }}
                  contentStyle={{
                    opacity: mult < 0.7 ? 0 : 0.7 + (mult / 3) * 10,
                    display: mult < 0.5 ? "hidden" : "visible",
                  }}
                  onClickRemove={this.removeMessage(style.key)}
                  {...style.data}
                >
                  {style.data.message}
                </Message>
              );
            })}
          </div>
        )}
      </TransitionMotion>
    );
  }
}

export default SystemMessageController;
