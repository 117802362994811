// TODO: isComputing vs. isCalculating? output vs. outputs?

import * as TYPES from "./types";

type StatePerGame = {
  isCalculating: boolean;
  outputs: any;
};

type State = { [s: string]: StatePerGame };

const initialState: State = {};
TYPES.TYPES.forEach(type => {
  initialState[type] = {
    isCalculating: false,
    outputs: null
  };
});

export default function(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_EMBEDDINGS_START:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          isComputing: true,
          output: null
        }
      };
    case TYPES.GET_EMBEDDINGS_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          isComputing: false,
          outputs: action.payload.outputs.map(([name, probability]) => ({
            name,
            probability
          }))
        }
      };
    case TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
}
