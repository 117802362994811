import cx from "classnames";
import * as React from "react";
import ContentLoader from "react-content-loader";
// @ts-ignore
import * as styles from "../AST.module.scss";

const GhostAST = () => (
  <div className={cx(styles.container, styles.containerEditorDirty)}>
    <div className={styles.spinnerContainer}>
      <ContentLoader
        height={450}
        width={300}
        speed={0.5}
        primaryColor={"#ececec"}
        secondaryColor={"#dfdfdf"}
      >
        <rect x="0" y="0" rx="10" ry="10" width="800" height="600" />
      </ContentLoader>
    </div>
  </div>
);

export default GhostAST;
