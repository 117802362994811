import cx from "classnames";
import * as React from "react";
import ContentLoader from "react-content-loader";
import Star from "../../../assets/svg/iconmonstr-star-3.svg";
import Card from "../../components/Card";
import * as styles from "./PredictionsList.module.scss";

import { Prediction } from "../../lib/api";

const TOP = 5;

interface Props {
  predictions?: Prediction[];
  isPredicting?: boolean;
  className?: string;
  shouldShowGhost: boolean;
}

const PROB_BOLD_THRESHOLD = 90;

const probabilityToPercentage = (prob) => Math.round(prob * 1e4) / 1e2;

interface PredictionProps {
  name: string;
  star: boolean;
  probability: number;
}

const PredictionCard = ({
  name,
  probability,
  isPredicting = false,
  star
}: PredictionProps) => (
  <Card className={styles.predictionCard}>
    <span className={styles.predictionCardName}>{name}</span>
    <span
      className={cx(styles.predictionCardProbability, {
        [styles.predictionCardProbabilityBold]:
          probability > PROB_BOLD_THRESHOLD
      })}
    >
      {String(probability) + "%"}
      {star && (
        <div
          className={cx(styles.starContainer, {
            [styles.starContainerShown]: !isPredicting
          })}
        >
          <Star className={styles.star} />
        </div>
      )}
    </span>
  </Card>
);

// TODO: abstract magic numbers
function renderGhostPredictions() {
  return (
    <div className={styles.ghostPredictions}>
      <div className={styles.ghostPredictionsOverlay} />
      <ContentLoader
        height={TOP * 52}
        width="340"
        speed="1"
        primaryColor={"#839FE6"}
        secondaryColor={"#BACAF1"}
      >
        {Array.from({ length: TOP }).map((_, i) => (
          <rect
            key={i}
            x="0"
            y={15 + i * 75}
            rx="8"
            ry="8"
            width="340"
            height="50"
          />
        ))}
      </ContentLoader>
    </div>
  );
}

function renderEmptyState(shouldShowGhost: boolean, isPredicting: boolean) {
  if (isPredicting && shouldShowGhost) return renderGhostPredictions();
  return null;
}

const PredictionsList = ({
  isPredicting = false,
  shouldShowGhost = true,
  predictions = null,
  className = null,
  ...rest
}: Props) => {
  if (isPredicting) return renderEmptyState(shouldShowGhost, true);
  if (predictions.length <= 0) return renderEmptyState(shouldShowGhost, false);
  return (
    <div className={cx(styles.predictions, className)}>
      {predictions.slice(0, TOP).map(({ name, probability }, index) => {
        const probDisplay = probabilityToPercentage(probability);
        return (
          <PredictionCard
            star={index === 0}
            name={name}
            isPredicting={isPredicting}
            probability={probDisplay}
            key={name + "-" + probDisplay}
          />
        );
      })}
    </div>
  );
};

export default PredictionsList;
