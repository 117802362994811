// https://coolors.co/app/080705-40434e-702632-912f40-fffffa

import { rgba } from "polished";

export const SHADOW = rgba("#000000", 1);
export const PRIMARY = rgba("#163172", 1);
export const PRIMARY_2 = rgba("#1E56A0", 1);
export const AUX = rgba("#00A6E7", 1);
export const WHITE = rgba("#FFFFFF", 1);
export const BLACK = rgba("#050510", 1);

export const TEXT_WHITE = WHITE;
