import * as anime from "animejs";
import cx from "classnames";
import { create } from "domain";
import { Link as RouterLink } from "gatsby";
import { darken, lighten, opacify } from "polished";
import * as React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
// @ts-ignore
import FileIcon from "../../../assets/svg/iconmonstr-file-22.svg";
// @ts-ignore
import VideoIcon from "../../../assets/svg/iconmonstr-video-13.svg";
// @ts-ignore
import GitHubIcon from "../../../assets/svg/iconmonstr-github-1.svg";
// @ts-ignore
import SlidesIcon from "../../../assets/svg/iconmonstr-layer-16.svg";
// @ts-ignore
import BibtexIcon from "../../../assets/svg/iconmonstr-quote-3.svg";
// @ts-ignore
import BlogIcon from "../../../assets/svg/iconmonstr-newspaper-4.svg";
// @ts-ignore
import ExamplesIcon from "../../../assets/svg/iconmonstr-help-5.svg";
import * as COLORS from "../../styles/theme/colors";
import { HERO_HEIGHT } from "../../styles/theme/config";
import Title from "./Title";

const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  transform: scale(1);
  margin-right: 10px;
  transition: transform 150ms ease, fill 150ms ease;
`;

const LinkLabel = styled.span`
  position: relative;
  color: ${COLORS.TEXT_WHITE};
  text-decoration: none;
  transform: scale(1);
  transition: transform 150ms ease;
  transform-origin: 0% 50%;
  font-size: 12px;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: calc(100% - 2px);
    background: ${opacify(-0.5, COLORS.TEXT_WHITE)};
  }
`;

const LinkWrapper = styled.a`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-decoration: none;
  margin: 0 12px;
  &:hover ${IconContainer} {
    transform: scale(1.2);
    fill: ${lighten(0.2, COLORS.AUX)};
  }
  &:hover ${LinkLabel} {
    transform: scale(1.05);
  }
`;

// TODO: consolidate with above
const NativeLinkWrapper = styled(RouterLink)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-decoration: none;
  margin: 0 12px;
  &:hover ${IconContainer} {
    transform: scale(1.2);
    fill: ${lighten(0.2, COLORS.AUX)};
  }
  &:hover ${LinkLabel} {
    transform: scale(1.05);
  }
`;

interface LinkProps {
  icon: React.SFC<any>;
  href: string;
  children: React.ReactChild | React.ReactChildren;
  native?: boolean;
}

const Link: React.SFC<LinkProps> = ({
  icon: Icon,
  href,
  children,
  native = true
}) => (
  <li>
    {native ? (
      <LinkWrapper href={href} target={href}>
        <IconContainer>
          <Icon style={{ width: "100%", height: "100%", fill: COLORS.AUX }} />
        </IconContainer>
        <LinkLabel>{children}</LinkLabel>
      </LinkWrapper>
    ) : (
      <NativeLinkWrapper to={href}>
        <IconContainer>
          <Icon style={{ width: "100%", height: "100%", fill: COLORS.AUX }} />
        </IconContainer>
        <LinkLabel>{children}</LinkLabel>
      </NativeLinkWrapper>
    )}
  </li>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const HeroContainer = styled.div`
  width: 100%;
  height: ${HERO_HEIGHT}px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  display: content;
  box-shadow: 0px 6px 11px rgba(${COLORS.SHADOW}, 0.1);
  z-index: 100;
`;

const HeroImage = styled.div`
  &::before {
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      55deg,
      #f6f6f6 0%,
      #b5c9de 30%,
      #a2b4c1 50%,
      #f6f6f6 100%
    );
  }
  &::after {
    z-index: 1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${opacify(-0.15, COLORS.PRIMARY)};
    background-blend-mode: darken;
  }
  width: 100%;
  height: 100%;
  z-index: 0;
  background: url(/images/hero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; // filter: blur(5px);
`;

const HeroImageWrapper = styled.div`
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-weight: normal;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const Subtitle = styled.div`
  color: darken(white, 15%);
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.6;
`;

const SubtitleMain = styled.span`
  font-size: 14px;
  position: relative;
  display: block;
  letter-spacing: 1px;
  color: ${COLORS.AUX};
  b {
    font-weight: bold;
    color: darken(${COLORS.TEXT_WHITE}, 15%);
  }
  a {
    color: darken(${COLORS.TEXT_WHITE}, 15%);
  }
`;

const SubSubtitleMain = styled.span`
  font-size: 14px;
  position: relative;
  display: block;
  letter-spacing: 1px;
  color: ${COLORS.AUX};
  font-style: italic;
  b {
    font-weight: bold;
    color: darken(${COLORS.TEXT_WHITE}, 15%);
  }
  a {
    color: darken(${COLORS.TEXT_WHITE}, 15%);
  }
`;

const SubtitleArticle = styled.span`
  position: relative;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 18px;
  color: ${COLORS.TEXT_WHITE};
  &:after {
    position: absolute;
    content: "";
    bottom: -4px;
    width: calc(100% + 12px);
    left: -6px;
    height: 3px;
    background: ${opacify(-0.85, COLORS.TEXT_WHITE)};
  }
  i {
    font-style: italic;
    color: ${darken(0.15, COLORS.TEXT_WHITE)};
    font-weight: 300;
  }
`;

const Links = styled.ul`
  margin-top: 28px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

class Hero extends React.PureComponent<{ className?: string }> {
  public componentDidMount() {
    anime
      .timeline({ loop: false })
      .add({
        targets: ".ml6",
        opacity: [0, 1],
        scaleX: [0.1, 1],
        easing: "easeInOutExpo",
        duration: 300
      })
      .add({
        targets: ".ml7",
        opacity: [0, 1],
        scaleY: [0.1, 1],
        easing: "easeInOutExpo",
        duration: 400
      })
      .add({
        targets: ".ml8",
        opacity: [0, 1],
        scaleY: [0.1, 1],
        easing: "easeInOutExpo",
        duration: 300
      });
  }
  public render() {
    const { className } = this.props;
    return (
      <Container className={className}>
        <HeroContainer>
          <HeroImage />
          <HeroImageWrapper>
            <Title />
            <Subtitle>
              <SubtitleMain className="ml6">
                Demonstration of principles shown in the paper&nbsp;
              </SubtitleMain>
              <SubtitleArticle className="ml7">
                <i>code2vec:</i> Learning Distributed Representations of Code
              </SubtitleArticle>
              <SubSubtitleMain className="ml7">
                Appeared in POPL'2019
              </SubSubtitleMain>
              <Links>
                <Link
                  icon={GitHubIcon}
                  href="https://github.com/tech-srl/code2vec"
                >
                  Source
                </Link>
                <Link icon={FileIcon} href="https://urialon.cswp.cs.technion.ac.il/wp-content/uploads/sites/83/2018/12/code2vec-popl19.pdf">
                  Paper
                </Link>
                <Link icon={VideoIcon} href="https://t.co/fjz2oVoDus">
                  Video
                </Link>
                <Link icon={ExamplesIcon} href="/examples" native={false}>
                  Examples
                </Link>
                <Link icon={BlogIcon} href="https://blog.sigplan.org/2020/02/12/from-programs-to-deep-models-part-2/">
                  Blog
                </Link>
                <Link icon={SlidesIcon} href="https://urialon.cswp.cs.technion.ac.il/wp-content/uploads/sites/83/2019/01/code2vec_popl19_slides.pdf">
                    Slides
                </Link>
                <Link icon={BibtexIcon} href="https://pastebin.com/raw/ddAkvska">
                    Cite
                </Link>
              </Links>
            </Subtitle>
          </HeroImageWrapper>
        </HeroContainer>
      </Container>
    );
  }
}

export default Hero;
