import cx from "classnames";
import * as React from "react";
import SpinningArrow from "../SpinningArrow";
import * as styles from "./CTAWithArrow.module.scss";

interface Props {
  isLoading: boolean;
  label: string;
  className?: string;
  arrowClassName?: string;
  onClick: () => void;
}

const CTAWithArrow = ({
  isLoading,
  label,
  className = null,
  arrowClassName = null,
  onClick = () => {}
}: Props) => (
  <div className={styles.container}>
    <div
      onClick={onClick}
      className={cx(styles.cta, { [styles.ctaLoading]: isLoading }, className)}
    >
      <div className={styles.ctaLabel}>{label}</div>
      <div
        className={cx(styles.arrowContainer, {
          [styles.arrowContainerActive]: isLoading
        })}
      >
        <SpinningArrow
          shouldSpin={isLoading}
          className={cx(styles.arrow, arrowClassName, {
            [styles.arrowActive]: isLoading
          })}
        />
      </div>
    </div>
  </div>
);

export default CTAWithArrow;
