import React from "react";
import cx from "classnames";
import WordGame from "../../containers/WordGame";
import PredictionsList from "../../components/PredictionsList";
import CTAWithArrow from "../../components/CTAWithArrow";
import styles from "./Playground.module.scss";

const options = {
  type: "COMBINATION",
  numInputs: 2,
  defaults: ["equals", "toLower"],
};

const MostSimilar = () => (
  <WordGame options={options}>
    {({ inputs, isComputing, compute, outputs }) => {
      const FirstInput = inputs[0];
      const SecondInput = inputs[1];
      return (
        <React.Fragment>
          <div className={styles.gameContainer}>
            <div className={styles.gameSection}>
              <FirstInput className={styles.input} />
              <div style={{ margin: "6px 0" }}>and</div>
              <SecondInput className={styles.input} />
            </div>
            <div className={cx(styles.gameSection, styles.gameMiddleSection)}>
              <div className={styles.actionDescription}>
                ...combined, are similar to:
              </div>
              <CTAWithArrow
                label="predict"
                onClick={compute}
                isLoading={isComputing}
                className={styles.cta}
              />
            </div>
            <div
              className={cx(styles.gameSection, styles.gameSectionPredictions)}
            >
              <PredictionsList
                isPredicting={isComputing}
                predictions={outputs}
                className={styles.predictions}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }}
  </WordGame>
);

export default MostSimilar;
