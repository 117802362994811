export function toCamelCase(str) {
  return str
    .split(" ")
    .map(function(word, index) {
      // If it is the first word make sure to lowercase all the chars.
      if (index === 0) {
        return word.toLowerCase();
      }
      // If it is not the first word only upper case the first char and lowercase the rest.
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
}

function getPosByIndex(str: string, word: string, index: number) {
  // calc start
  const newlineRegExp = new RegExp(/\n/, "g");
  let match,
    lastNewlineIndex = -1,
    newLines = 0;
  do {
    match = newlineRegExp.exec(str);
    if (match && match.index < index) newLines++;
    if (match && match.index >= index) break;
    lastNewlineIndex = match ? match.index : lastNewlineIndex;
  } while (match && lastNewlineIndex < index);
  const start = [newLines, index - lastNewlineIndex - 1];
  const end = [start[0], start[1] + word.length - 1];
  if (start[0] > end[0] || start[1] > end[1]) {
    // start and end are switched
    return { start: end, end: start };
  }
  return { start, end };
}

export function getWordPosInStrByOccurr(str: string) {
  return (word: string, occurr: number = 0) => {
    let regexp;
    try {
      regexp = new RegExp(`${word}`, "gi");
    } catch (e) {
      return null;
    }
    let match,
      lastIndex = -1,
      i = 0;
    do {
      match = regexp.exec(str);
      lastIndex = match ? match.index : lastIndex;
      i++;
    } while (match && i < occurr + 1);
    return getPosByIndex(str, word, lastIndex);
  };
}
