import { getPredictions as getPredictionsAPI } from "../../lib/api";
import { addSystemMessage } from "../systemMessage/actions";
import * as TYPES from "./types";

import { Prediction } from "../../lib/api";

// TODO: add more info for parsing error (stacktrace etc.)
function getParsingErrorMessageText(errMessage) {
  return `Parsing error at line ${errMessage.match(/line (\d*)/)[1]}`;
}

export function getPredictions({
  code = "",
  syntax = "JAVA"
}: {
  code?: string;
  syntax?: string;
}) {
  return (dispatch: Dispatch) => {
    if (!code) {
      addSystemMessage({
        type: "TOAST",
        title: "Error",
        message: "Editor must contain code."
      })(dispatch);
      return;
    }
    dispatch({
      type: TYPES.GET_PREDICTIONS_START
    });
    getPredictionsAPI(code)
      .then((parsedPredictions: Prediction[]) => {
        dispatch({
          type: TYPES.GET_PREDICTIONS_SUCCESS,
          payload: parsedPredictions
        });
      })
      .catch(err => {
        dispatch({
          type: TYPES.GET_PREDICTIONS_FAILED
        });
        if (err.fromServer) {
          addSystemMessage({
            type: "TOAST",
            title: err.errorType.includes("Parse") ? "Parsing error" : "Error",
            message: getParsingErrorMessageText(err.errorMessage)
          })(dispatch);
        } else {
          addSystemMessage({
            type: "TOAST",
            title: "Network error",
            message:
              "Connection with server failed \n(code: " + Date.now() + ")"
          })(dispatch);
        }
      });
  };
}

export function setEditorDirty() {
  return dispatch => {
    dispatch({
      type: TYPES.SET_EDITOR_DIRTY
    });
  };
}

export function resetEditorDirty() {
  return dispatch => {
    dispatch({
      type: TYPES.RESET_EDITOR_DIRTY
    });
  };
}
