import * as anime from "animejs";
import * as React from "react";
import { createGlobalStyle } from "styled-components/macro";
import * as COLORS from "../../styles/theme/colors";

const GlobalStyle = createGlobalStyle`
  .ml5 {
    position: relative;
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 12px;
    color: white;
  }

  .ml5 .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.25em;
    line-height: 1em;
  }

  .ml5 .line {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 3px;
    width: 100%;
    background-color: ${COLORS.PRIMARY_2};
    transform-origin: 0.5 0;
  }

  .ml5 .ampersand {
    color: ${COLORS.AUX};
    font-weight: 400;
    width: 1em;
    margin-right: -0.1em;
    margin-left: -0.1em;
  }

  .ml5 .letters {
    display: inline-block;
    opacity: 0;
  }
`;

const DURATION_MULT = 50;

class Title extends React.PureComponent {
  public componentDidMount() {
    anime
      .timeline({ loop: false })
      .add({
        targets: ".line",
        opacity: [0, 1],
        scaleX: [0.5, 1],
        easing: "easeInOutExpo",
        duration: DURATION_MULT * 1,
        delay: 500
      })
      .add({
        targets: ".line",
        opacity: [0, 1],
        easing: "easeInOutExpo",
        duration: DURATION_MULT * 7
      })
      .add({
        targets: ".line",
        duration: DURATION_MULT * 6,
        easing: "easeOutExpo",
        translateY(e, i, l) {
          const offset = -0.625 + 0.625 * 2 * i;
          return offset + "em";
        }
      })
      .add({
        targets: ".ml5 .ampersand",
        opacity: [0, 1],
        scaleY: [0.5, 1],
        easing: "easeOutExpo",
        duration: DURATION_MULT * 6,
        offset: "-=600"
      })
      .add({
        targets: ".ml5 .letters-left",
        opacity: [0, 1],
        translateX: ["0.5em", 0],
        easing: "easeOutExpo",
        duration: DURATION_MULT * 6,
        offset: "-=300"
      })
      .add({
        targets: ".ml5 .letters-right",
        opacity: [0, 1],
        translateX: ["-0.5em", 0],
        easing: "easeOutExpo",
        duration: DURATION_MULT * 6,
        offset: "-=600"
      });
  }

  public render() {
    return (
      <>
        <GlobalStyle />
        <h1 className="ml5">
          <span className="text-wrapper">
            <span className="line line1" />
            <span className="letters letters-left">CODE</span>
            <span className="letters ampersand">2</span>
            <span className="letters letters-right">VEC</span>
            <span className="line line2" />
          </span>
        </h1>
      </>
    );
  }
}

export default Title;
