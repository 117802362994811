import React from "react";
import TabView from "../../containers/TabView";
import MostSimilar from "./MostSimilar";
import Combinations from "./Combinations";
import Analogies from "./Analogies";
import styles from "./Playground.module.scss";

const tabs = [
  {
    label: "Most Similar",
    component: MostSimilar,
  },
  {
    label: "Name Combinations",
    component: Combinations,
  },
  {
    label: "Analogies",
    component: Analogies,
  },
];

const Playground = () => (
  <TabView tabs={tabs}>
    {({ TabsComponent, ActiveTabComponent }) => (
      <div className={styles.container}>
        <TabsComponent className={styles.tabs} />
        <ActiveTabComponent />
      </div>
    )}
  </TabView>
);

export { MostSimilar, Combinations, Analogies };

export default Playground;
