import * as React from "react";
import AuthorsGrid from "../../components/AuthorsGrid";
// @ts-ignore
import * as styles from "./Info.module.scss";

const Info = () => (
  <div className={styles.container}>
    <div className={styles.section}>
      <p className={styles.infoText}>
        <span className={styles.code2vec}>code2vec</span> is a dedicated website
        for demonstrating the principles shown in the paper
        <br />
        <span className={styles.paper}>
          code2vec: Learning Distributed Representations of Code
        </span>
      </p>
    </div>
    <div className={styles.section}>
      <h3 className={styles.title}>Authors</h3>
      <AuthorsGrid className={styles.authorsGrid} />
    </div>
  </div>
);

export default Info;
