import * as cx from "classnames";
import * as React from "react";
import Credits from "../../components/Credits";
import HelpTip from "../../components/HelpTip";
import Section from "../../components/Section";
import Sidebar from "../../components/Sidebar";
import Guesser from "../../containers/Guesser";
import Info from "../../containers/Info";
import {
  Analogies,
  Combinations,
  MostSimilar
} from "../../containers/Playground";
import TabNav from "../../containers/TabNav";
// @ts-ignore
import * as styles from "./Sections.module.scss";

const CREDITS = [
  {
    title: "Icons",
    subtitle: "IconMonstr",
    src: "https://iconmonstr.com/"
  },
  {
    title: "Website by",
    subtitle: "Guy Waldman",
    src: "https://github.com/guywald1"
  },
  {
    title: "Hero Image",
    subtitle: "Unsplash",
    src: "http://unsplash.com"
  }
];

class GuesserSection extends React.Component<any> {
  public render() {
    const { isAtTop } = this.props;
    return (
      <React.Fragment>
        <Section className={styles.guesserSection}>
          <Guesser isScrolledToTop={isAtTop} />
        </Section>
      </React.Fragment>
    );
  }
}

const MostSimilarHelpTip = () => (
  <HelpTip>
    Predict the most similar word in meaning.
    <HelpTip.Example>"count" -> "length"</HelpTip.Example>
  </HelpTip>
);

class MostSimilarSection extends React.Component {
  public render() {
    return (
      <React.Fragment>
        <div className={styles.titleContainerDark}>
          <h1 className={styles.title}>Playground</h1>
        </div>
        <Section
          className={styles.playgroundSection}
          HelpTip={MostSimilarHelpTip}
          title="Most Similar"
        >
          <MostSimilar />
        </Section>
      </React.Fragment>
    );
  }
}

const CombinationsHelpTip = () => (
  <HelpTip>
    Find combination of names.
    <HelpTip.Example>
      "equals" + "toLower" -> "equalsIgnoreCase"
    </HelpTip.Example>
  </HelpTip>
);
class CombinationsSection extends React.Component {
  public render() {
    return (
      <Section
        className={styles.playgroundSection}
        HelpTip={CombinationsHelpTip}
        title="Combinations"
      >
        <Combinations />
      </Section>
    );
  }
}

const AnalogiesHelpTip = () => (
  <HelpTip>
    Figure out SAT-style analogies.
    <HelpTip.Example>
      "Receive" is to "download" as "send" is to -> "upload"
    </HelpTip.Example>
  </HelpTip>
);
class AnalogiesSection extends React.Component {
  public render() {
    return (
      <Section
        className={cx(styles.analogiesSection, styles.playgroundSection)}
        HelpTip={AnalogiesHelpTip}
        title="Analogies"
      >
        <Analogies />
      </Section>
    );
  }
}

class InfoSection extends React.Component {
  public render() {
    return (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>About</h1>
        </div>
        <Section>
          <Info />
        </Section>
      </React.Fragment>
    );
  }
}

class CreditsSection extends React.Component {
  public render() {
    return (
      <React.Fragment>
        <div className={styles.titleContainerDark}>
          <h1 className={styles.title}>Website Credits</h1>
        </div>
        <Section className={styles.sectionDark}>
          <Credits credits={CREDITS} />
        </Section>
      </React.Fragment>
    );
  }
}

const Predict = () => (
  <TabNav
    sections={[
      GuesserSection,
      MostSimilarSection,
      CombinationsSection,
      AnalogiesSection,
      InfoSection,
      CreditsSection
    ]}
  >
    {({
      activeSectionIndex,
      SectionComponent,
      isAtTop,
      renderChildren,
      onClickLabel,
      changeActiveSectionTo
    }) => (
      <div className={styles.container}>
        <Sidebar
          isAtTop={isAtTop}
          onClickLabel={onClickLabel}
          activeIndex={activeSectionIndex}
          className={styles.nav}
        />
        <div className={styles.main}>{renderChildren({ isAtTop })}</div>
      </div>
    )}
  </TabNav>
);

export default Predict;
