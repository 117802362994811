import * as React from "react";
import { lazyload } from "react-lazyload";
// @ts-ignore
import IconWebsite from "../../../../assets/svg/iconmonstr-globe-1.svg";
// @ts-ignore
import * as styles from "./AuthorCard.module.scss";

interface Props {
  title: string;
  affiliation: string;
  imageSrc: string;
  imageStyle?: any;
  website?: string | null;
}

@lazyload({
  height: 128,
  once: true,
  offset: 100
})
class AuthorCard extends React.Component<Props> {
  public render() {
    const { imageSrc, imageStyle, website, affiliation, title } = this.props;
    return (
      <li className={styles.container}>
        <div className={styles.imgWrapper}>
          <div className={styles.imgContainer}>
            <img src={imageSrc} alt="" style={imageStyle} />
          </div>
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.affiliation}>{affiliation}</div>
        <ul className={styles.contact}>
          {website && (
            <li className={styles.contactItem}>
              <a href={website || ""}>
                <IconWebsite className={styles.icon} />
              </a>
            </li>
          )}
        </ul>
      </li>
    );
  }
}

export default AuthorCard;
