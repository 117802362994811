import cx from "classnames";
import * as React from "react";
import * as styles from "./Sidebar.module.scss";

const LABELS = [
  "Prediction",
  "Most Similar",
  "Combinations",
  "Analogies",
  "About",
  "Credits"
];

interface LabelsProps {
  activeIndex: number;
  labels: string[];
  title?: string;
  startIndex: number;
  onClickLabel: (number) => void;
}
const Labels = ({
  title = null,
  labels,
  onClickLabel,
  startIndex,
  activeIndex
}: LabelsProps) => (
  <div className={styles.sectionContainer}>
    {title && <h3 className={styles.sectionTitle}>{title}</h3>}
    <ul className={styles.section}>
      {labels.map((l, i) => (
        <li
          key={l}
          className={cx(styles.sectionLabel, {
            [styles.sectionLabelActive]: activeIndex === i
          })}
        >
          <button
            className={styles.button}
            onClick={() => onClickLabel(i + startIndex)}
          >
            {l}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

interface SidebarProps {
  className: string | null;
  activeIndex: number;
  isAtTop: boolean;
  onClickLabel: (number) => void;
}
const Sidebar = ({
  className,
  onClickLabel,
  isAtTop,
  activeIndex
}: SidebarProps) => (
  <div className={cx(styles.container, className)}>
    <div className={cx(styles.sidebar, isAtTop && styles.sidebarTop)}>
      <Labels
        startIndex={0}
        labels={LABELS.slice(0, 1)}
        onClickLabel={onClickLabel}
        activeIndex={activeIndex}
      />
      <Labels
        title="Playground"
        startIndex={1}
        labels={LABELS.slice(1, 4)}
        onClickLabel={onClickLabel}
        activeIndex={activeIndex - 1}
      />
      <Labels
        startIndex={4}
        labels={LABELS.slice(4)}
        onClickLabel={onClickLabel}
        activeIndex={activeIndex - 4}
      />
    </div>
  </div>
);

export default Sidebar;
