import cx from "classnames";
import { Link } from "gatsby";
import * as React from "react";
import * as styles from "./Menu.module.scss";
import MenuLink from "./MenuLink";

interface Props {
  children: any;
  dark?: boolean;
}

const Menu = ({ children = null, dark = false }: Props) => (
  <div className={cx(styles.menu, { [styles.menuDark]: dark })}>
    <div className={styles.header}>
      <div className={styles.logo}>
        <Link to="/" className={styles.logo}>
          Code
          <span className={styles.logoItalic}>2</span>
          Vec
        </Link>
      </div>
    </div>
  </div>
);

export default Menu;
