import * as cx from "classnames";
import * as React from "react";
import * as styles from "./Card.module.scss";

import { Node } from "react";

interface Props {
  children?: Node;
  sideTitle?: string;
  style?: any;
  className?: string;
}

const Card = ({
  children,
  className = null,
  sideTitle = null,
  ...rest
}: Props) => (
  <div className={cx(styles.card, className)} {...rest}>
    {sideTitle && <div className={styles.sideTitle}>{sideTitle}</div>}
    {children}
  </div>
);

export default Card;
