import * as React from "react";
import { connect } from "react-redux";
import GhostAST from "../../components/AST/GhostAST";
// import PredictionsList from '../../components/PredictionsList'
import TabView from "../../containers/TabView";
import styles from "./PredictionsViz.module.scss";

// for SSR
const ASTStub =
  typeof window !== "undefined"
    ? require("../AST").default
    : (props: any) => <div />;

const mapStateToProps = ({ predictions }) => ({
  ast: predictions.ast,
  isEditorDirty: predictions.isEditorDirty,
  attentionPaths: predictions.attentionPaths
});

// TODO: remove isLoading prob from ASTStub

@connect(mapStateToProps)
class PredictionViz extends React.Component {
  public getTabs = () => [
    {
      label: "AST",
      component: () =>
        this.props.isPredicting ? (
          <GhostAST />
        ) : (
          <ASTStub
            isLoading={this.props.isPredicting}
            isEditorDirty={this.props.isEditorDirty}
            ast={this.props.ast}
            attentionPaths={this.props.attentionPaths}
          />
        )
    }
  ]
  public render() {
    const tabs = this.getTabs();
    const disabled =
      !this.props.isPredicting && this.props.predictions.size === 0;
    return (
      <div className={styles.wrapper}>
        <TabView disabled={disabled} tabs={tabs}>
          {({ TabsComponent, disabled, ActiveTabComponent }) => (
            <div className={styles.container}>
              <div className={styles.tabs}>
                <TabsComponent />
              </div>
              <div className={styles.tab}>
                {!disabled && <ActiveTabComponent />}
              </div>
            </div>
          )}
        </TabView>
      </div>
    );
  }
}

export default PredictionViz;
