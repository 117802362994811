import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reduxPromiseMiddleware from "redux-promise-middleware";
import reduxThunkMiddleware from "redux-thunk";
import reducers from "./reducers";
// import createHistory from "history/createBrowserHistory";

// export const history = createHistory();

// @ts-ignore
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  combineReducers({
    ...reducers
  }),
  composeEnhancers(
    applyMiddleware(reduxPromiseMiddleware(), reduxThunkMiddleware)
  )
);

export default store;
