import { List } from "immutable";
import * as TYPES from "./types";

// import { List as ListT } from "immutable";
import { Prediction } from "../../lib/api";

export type PredictionReducerState = {
  timestamp?: number;
  isGuessing: boolean;
  isEditorDirty: boolean;
  names: Prediction[];
};

const initialState: PredictionReducerState = {
  names: [],
  isEditorDirty: false,
  isGuessing: false
};

export default (state: PredictionReducerState = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PREDICTIONS_START:
      return { ...state, isGuessing: true };
    case TYPES.GET_PREDICTIONS_SUCCESS:
      return {
        ...state,
        names: action.payload.predictions,
        ast: action.payload.AST,
        attentionPaths: action.payload.attention_paths,
        isGuessing: false
      };
    case TYPES.GET_PREDICTIONS_FAILED:
      return { ...initialState, isEditorDirty: state.isEditorDirty };
    case TYPES.SET_EDITOR_DIRTY:
      return { ...state, isEditorDirty: true };
    case TYPES.RESET_EDITOR_DIRTY:
      return { ...state, isEditorDirty: false };
    default:
      return state;
  }
};
