import * as cx from "classnames";
import * as React from "react";
import * as styles from "./TabView.module.scss";

// -------------------------- FLOW -------------------------

export interface TabInfo {
  label: string;
  disabled?: boolean;
  component: JSX.Element;
}

// ----------------------- COMPONENT -----------------------

interface ChildProps {
  ActiveTabComponent: JSX.Element;
  TabsComponent: JSX.Element;
  disabled?: boolean;
}

interface Props {
  tabs: TabInfo[];
  disabled: boolean;
  onTabToggle: (number) => void;
  children(ChildProps): JSX.Element;
}

interface State {
  activeTab: number;
}

class TabView extends React.Component<Props, State> {
  public static defaultProps = {
    tabs: [],
    disabled: false,
    onTabToggle: () => {},
    children: () => {}
  };
  public state = {
    activeTab: 0
  };
  public switchTab = (tabIndex: number) => () => {
    this.props.onTabToggle(tabIndex);
    this.setState({ ...this.state, activeTab: tabIndex });
  }
  public getActiveComponent = () => {
    return this.props.tabs[this.state.activeTab].component;
  }
  public renderTabs = () => {
    return ({ className, ...rest }) => (
      <ul
        className={cx(
          styles.tabs,
          {
            [styles.tabsDisabled]: this.props.disabled
          },
          className
        )}
        {...rest}
      >
        {this.props.tabs.map(({ label, disabled }: TabInfo, index) => (
          <li key={label + "_" + index}>
            <button
              className={cx(styles.tab, {
                [styles.tabActive]: index === this.state.activeTab,
                [styles.tabDisabled]: disabled
              })}
              onClick={this.switchTab(index)}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
    );
  }
  public render() {
    if (this.props.tabs.length === 0) {
      return null;
    }
    const { renderTabs, getActiveComponent } = this;
    return this.props.children({
      TabsComponent: renderTabs(),
      disabled: this.props.disabled,
      ActiveTabComponent: getActiveComponent()
    });
  }
}

export default TabView;
