export const GET_EMBEDDINGS_START = "@@PLAYGROUND/GET_EMBEDDINGS_START";
export const GET_EMBEDDINGS_FAILURE = "@@PLAYGROUND/GET_EMBEDDINGS_FAILURE";
export const GET_EMBEDDINGS_SUCCESS = "@@PLAYGROUND/GET_EMBEDDINGS_SUCCESS";
export const CLEAR = "@@PLAYGROUND/CLEAR";

export const SIMILAR = "SIMILAR";
export const COMBINATION = "COMBINATION";
export const ANALOGY = "ANALOGY";

export const TYPES = [SIMILAR, COMBINATION, ANALOGY];
