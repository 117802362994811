import cx from "classnames";
import * as React from "react";
// @ts-ignore
import styles from "./NodeLabel.module.scss";

const STR_LENGTH_LIMIT = 12;

enum ASTNode {
  MethodDeclaration,
  Terminal
}

const camelPad = (str) =>
  str
    .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
    .replace(/([a-z\d])([A-Z])/g, "$1 $2")
    .replace(/([a-zA-Z])(\d)/g, "$1 $2")
    .trim();

const breakPad = (str) => {
  if (str.length <= STR_LENGTH_LIMIT) return str;
  const paddedStr = camelPad(str);
  return paddedStr.split(" ").join("\n");
};
class NodeLabel extends React.Component<any> {
  public static defaultProps = {
    nodeData: null
  };
  public render() {
    const { nodeData } = this.props;
    const isLeaf = !nodeData._children;
    return (
      <div
        className={cx(styles.label, {
          [styles.labelCollapsed]: nodeData._collapsed && !isLeaf
        })}
      >
        {breakPad(nodeData.name)}
      </div>
    );
  }
}

export default NodeLabel;
