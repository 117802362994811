import cx from "classnames";
import * as React from "react";
// @ts-ignore
import IconArrow from "../../../assets/svg/iconmonstr-arrow-17.svg";
import * as styles from "./PredictCTA.module.scss";

interface Props {
  isPredicting?: boolean;
}

const PredictCTA = ({ isPredicting = false, ...rest }: Props) => (
  <div className={styles.container} {...rest}>
    <IconArrow
      className={cx(styles.arrow, { [styles.arrowIsPredicting]: isPredicting })}
    />
  </div>
);

export default PredictCTA;
