import React from "react";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import store from "../redux/store";
import Layout from "../containers/Layout";
import SystemMessageController from "../containers/SystemMessageController";
import Sections from "../containers/Sections";
import Hero from "../components/Hero";

import "../styles/global/reset.css";
import "../styles/global/global.css";
import { warmUpLambdas } from "../lib/api";

const TITLE = "code2vec";
const DESCRIPTION =
  'Demonstration of principles shown in the paper "code2vec: learning distributed representations of code"';

class App extends React.Component {
  componentDidMount() {
    warmUpLambdas();
  }
  render() {
    return (
      <Provider store={store}>
        <Layout>
          <Helmet
            htmlAttributes={{ lang: "en" }}
            meta={[{ name: "description", content: DESCRIPTION }]}
            title={TITLE}
          />
          <Hero />
          <Sections />
          <SystemMessageController />
        </Layout>
      </Provider>
    );
  }
}

export default App;
