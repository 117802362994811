import * as cx from "classnames";
import * as React from "react";
import Menu from "../../components/Menu";
// import Helmet from "react-helmet";
import * as styles from "./Layout.module.scss";

interface Props {
  noPadding?: boolean;
  dark?: boolean;
  fullScreen?: boolean;
  children: JSX.Element | JSX.Element[];
}

const Layout = ({
  children,
  noPadding = false,
  dark = false,
  fullScreen = true,
  ...rest
}: Props) => (
  <div
    className={cx(styles.main, {
      [styles.mainDark]: dark,
      [styles.mainFullScreen]: fullScreen
    })}
  >
    <div
      className={cx(styles.content, { [styles.contentNoPadding]: noPadding })}
    >
      {children}
    </div>
    <Menu className={styles.menu} dark={dark} />
  </div>
);

export default Layout;
