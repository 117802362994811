import { toCamelCase } from "./text-helpers";
// import mockJSON from './mock.json'

const SERVER_URL = "https://urialon.com:443/predict";

const LAMBDA_WARM_UP_URL =
  "https://po3g2dx2qa.execute-api.us-east-1.amazonaws.com/production/extractmethods";
const EMBEDDINGS_WARM_UP_URL =
  "https://12v3ms1y20.execute-api.us-east-1.amazonaws.com/production";

// name embeddings server
const SERVER_NE_URL =
  "https://12v3ms1y20.execute-api.us-east-1.amazonaws.com/production";

const FETCH_OPTIONS = {
  method: "POST"
};

export interface Prediction {
  name: string[];
  probability: number;
}

export interface PredictionsPerMethod {
  original_name: string;
  predictions: Prediction[];
  // TODO: complete type
  attention_paths: any;
}

export type Response = PredictionsPerMethod[];

// ------------------------ HELPERS ------------------------

async function resolveName(predictionName: string[]) {
  return Promise.resolve(toCamelCase(predictionName.join(" ")));
}

async function resolvePredictionNames(predictions: Prediction[]) {
  const predictionNames = predictions.map(({ name, ...rest }) => name);
  return Promise.all(predictionNames.map(resolveName));
}

export async function parsePredictions(predictions: Prediction[]) {
  const resolvedPredictionNames = await resolvePredictionNames(predictions);
  return resolvedPredictionNames.map((name, i) => ({
    ...predictions[i],
    name
  }));
}

// for testing
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function warmUpPredictionsLambda() {
  window.fetch(LAMBDA_WARM_UP_URL, {
    ...FETCH_OPTIONS,
    body: JSON.stringify({ code: "" })
  });
}

function warmUpEmbeddingsLambda() {
  window.fetch(EMBEDDINGS_WARM_UP_URL, {
    ...FETCH_OPTIONS
  });
}

// TODO: document
export async function getPredictionInfo(code: string): Promise<any> {
  const res = await window.fetch(SERVER_URL, {
    ...FETCH_OPTIONS,
    cors: true,
    body: code
  });
  const json = await res.json();
  return json;
}

/**
 * Receives Java code that contains a function and returns a promise that resolves
 * with the predictions.
 * @param {string} code code to predict
 */
export async function getPredictions(code: string): Promise<any> {
  const json = await getPredictionInfo(code);
  if (json.errorType) {
    return Promise.reject({ ...json, fromServer: true });
  }
  const predictions = await parsePredictions(json[0].predictions);
  return { ...json[0], predictions };
}

export async function getSimilarNames(
  words: string[]
): Promise<Array<[string, number]>> {
  const res = await window.fetch(SERVER_NE_URL, {
    ...FETCH_OPTIONS,
    cors: true,
    body: JSON.stringify({ positive: words })
  });
  const json = await res.json();
  return json;
}

export async function getCombinations(
  words: string[]
): Promise<Array<[string, number]>> {
  const res = await window.fetch(SERVER_NE_URL, {
    ...FETCH_OPTIONS,
    cors: true,
    body: JSON.stringify({ positive: words })
  });
  const json = await res.json();
  return json;
}

// first[0] is to second[0] as first[1] is to RESULT
export async function getAnalogies(
  first: string[],
  second: string[]
): Promise<Array<[string, number]>> {
  const data = JSON.stringify({ positive: first, negative: second });
  const res = await window.fetch(SERVER_NE_URL, {
    ...FETCH_OPTIONS,
    cors: true,
    body: JSON.stringify({ positive: first, negative: second })
  });
  const json = await res.json();
  return json;
}

/**
 * Warm up lambdas (to be used on initial load).
 * Returns a promise that fulfills with an array of the response received from
 * all the lambdas with the body containing empty code.
 */
export function warmUpLambdas(): Promise<any[]> {
  return Promise.all([warmUpPredictionsLambda(), warmUpEmbeddingsLambda()]);
}
