// @ts-ignore
import throttle from "lodash/throttle";
import * as React from "react";
import * as ReactDOM from "react-dom";

const SCROLL_TOP_LIM = 20;

// TODO: use IntersectionObserver API and fallback to throttled scroll listener
interface RenderProps {
  activeSectionIndex: number;
  changeActiveSectionTo(): void;
  renderChildren(): JSX.Element;
  isAtTop: boolean;
  onClickLabel(number): void;
}
type RenderPropsFunc = (RenderProps) => JSX.Element;
interface Props {
  sections: any[];
  children: RenderPropsFunc;
}
interface State {
  activeSectionIndex: number;
  isAtTop: boolean;
}

class TabNav extends React.Component<Props, State> {
  public sectionRefs: any[] = [];
  public itemTopOffsets: any[] = [];

  public state: State = {
    activeSectionIndex: 0,
    isAtTop: true
  };

  public handleScroll = () => {
    const itemTopOffsets = this.calcTopOffsets();
    const scrollY = window.scrollY;
    const activeSectionIndex =
      itemTopOffsets.findIndex((itemTopOffset, i) => {
        const nextItemTopOffset = itemTopOffsets[i + 1];
        if (nextItemTopOffset) {
          if (i === 0) {
            return (
              scrollY >= itemTopOffset + 300 && scrollY < nextItemTopOffset
            );
          }
          return scrollY >= itemTopOffset && scrollY < nextItemTopOffset;
        }
        return scrollY >= itemTopOffset;
      }) + 1;
    this.setState({
      ...this.state,
      activeSectionIndex,
      isAtTop: scrollY <= SCROLL_TOP_LIM
    });
  }
  public handleScrollThrottled = throttle(this.handleScroll, 60);

  public componentDidMount() {
    window.addEventListener("scroll", this.handleScrollThrottled);
    window.addEventListener("resize", this.handleScrollThrottled);
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollThrottled);
    window.removeEventListener("resize", this.handleScrollThrottled);
  }

  public calcTopOffsets() {
    // @ts-ignore
    return this.sectionRefs.map((n) => ReactDOM.findDOMNode(n).offsetTop);
  }

  public makeChildrenRenderer = () => {
    return (props) =>
      this.props.sections.map((node, index) =>
        React.createElement(node, {
          key: index,
          ref: (ref) => {
            this.sectionRefs[index] = ref;
          },
          ...props
        })
      );
  }

  public handleClickLabel = (sectionIndex) => {
    const itemTopOffsets = this.calcTopOffsets();
    const sectionTop = itemTopOffsets[sectionIndex];
    const top = sectionTop - 50 < 0 ? 0 : sectionTop - 50;
    window.scrollTo({ top, behavior: "smooth" });
  }

  public render() {
    const { activeSectionIndex, isAtTop } = this.state;
    const renderChildren = this.makeChildrenRenderer();
    return this.props.children({
      activeSectionIndex,
      renderChildren,
      isAtTop,
      onClickLabel: this.handleClickLabel
    });
  }
}

export default TabNav;
