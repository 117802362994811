import * as cx from "classnames";
import * as React from "react";
import { Motion, spring } from "react-motion";
// @ts-ignore
import HelpIcon from "../../../assets/svg/iconmonstr-help-2.svg"; // tslint:disable-line
// @ts-ignore
import XIcon from "../../../assets/svg/iconmonstr-x-mark-1.svg"; // tslint:disable-line
import * as styles from "./HelpTip.module.scss";

const Example = ({ children = null }) => (
  <div className={styles.example}>
    <h3 className={styles.exampleTitle}>Example</h3>
    <div
      dangerouslySetInnerHTML={{
        __html: children.replace(
          /("\w*")/g,
          (str) => `<b>${str.substr(1, str.length - 2)}</b>`
        )
      }}
    />
  </div>
);

interface Props {
  className?: string | null;
}

class HelpTip extends React.Component<Props> {
  public static Example = Example;
  public state = {
    open: false
  };
  public toggleOpenState = () => {
    this.setState({ open: !this.state.open });
    // TODO: focus on SVG?
  }
  public render() {
    return (
      <div className={cx(styles.wrapper, this.props.className)}>
        <Motion
          defaultStyle={{ h: 0 }}
          style={{ h: this.state.open ? spring(1) : spring(0) }}
        >
          {(interpStyle) => (
            <div
              className={styles.container}
              style={{ height: 20 + interpStyle.h * 50 + "px" }}
            >
              {this.state.open ? (
                <React.Fragment>
                  <XIcon
                    tabIndex={0}
                    onClick={this.toggleOpenState}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.toggleOpenState();
                    }}
                    className={styles.icon}
                  />
                </React.Fragment>
              ) : (
                <HelpIcon
                  tabIndex={0}
                  onClick={this.toggleOpenState}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") this.toggleOpenState();
                  }}
                  className={styles.icon}
                />
              )}
              <div
                className={cx(styles.text, {
                  [styles.textVisible]: this.state.open
                })}
              >
                {this.props.children}
              </div>
            </div>
          )}
        </Motion>
      </div>
    );
  }
}

export default HelpTip;
