import cx from "classnames";
import debounce from "lodash/debounce";
import * as React from "react";
import Tree from "react-d3-tree";
// @ts-ignore
import * as styles from "./AST.module.scss";
import GhostAST from "./GhostAST";
import { highlightAPs, parseAttentionPaths, parseRawAST } from "./lib";
import NodeLabel from "./NodeLabel";

const nodeLabelComponent = {
  render: <NodeLabel />,
  foreignObjectWrapper: {
    x: -235,
    y: -100,
    width: 480,
    height: 190
  }
};

interface State {
  ast: string | null;
  ap: number[][] | null;
  apScores: number[] | null;
  translate: {
    x: number;
    y: number;
  };
}
interface Props {
  isLoading: boolean;
  ast: string | null;
  isEditorDirty: boolean;
  attentionPaths: any[] | null;
}

class AST extends React.Component<Props, State> {
  // ref
  public treeContainer: any;

  public static defaultProps = {
    isLoading: true,
    ast: null,
    isEditorDirty: false,
    attentionPaths: null
  };
  public state: State = {
    ast: null,
    ap: null,
    apScores: null,
    translate: {
      x: 0,
      y: 0
    }
  };

  constructor(props) {
    super(props);
    if (props.ast) {
      const { ap, scores: apScores } = parseAttentionPaths(
        props.attentionPaths
      );
      let ast = parseRawAST(props.ast);
      ast = highlightAPs(ast, apScores, ap.slice(0, 4));
      this.state = {
        ast,
        ap,
        apScores,
        translate: {
          x: 0,
          y: 0
        }
      };
    }
  }

  public resizeHandler = () => {
    this.forceUpdate();
  }

  public debouncedResizeHandler = debounce(this.resizeHandler, 300, {
    leading: false,
    trailing: true
  });

  public componentDidMount() {
    window.addEventListener("resize", this.debouncedResizeHandler);
    if (!this.treeContainer) return;
    const dimensions = this.treeContainer.getBoundingClientRect();
    this.setState({
      ...this.state,
      translate: {
        x: dimensions.width / 2,
        y: dimensions.height / 5
      }
    });
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedResizeHandler);
  }

  public componentWillReceiveProps(props) {
    const { ap, scores: apScores } = parseAttentionPaths(props.attentionPaths);
    let ast = parseRawAST(props.ast);
    ast = highlightAPs(ast, apScores, ap.slice(0, 4));
    this.setState({
      ast,
      ap,
      apScores
    });
  }
  public render() {
    const { ast, translate } = this.state;
    if (!ast) return null;
    if (this.props.isLoading) {
      return <GhostAST />;
    }
    if (this.props.isEditorDirty) {
      return (
        <div className={cx(styles.container, styles.containerEditorDirty)}>
          <div className={styles.editorDirtyCopy}>
            Click on the arrow to generate <i>AST</i>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.container}>
        <div
          className={styles.astContainer}
          ref={(ref) => {
            this.treeContainer = ref;
          }}
        >
          {ast && (
            <Tree
              data={[ast]}
              nodeSize={{
                x: 150,
                y: 300
              }}
              orientation="vertical"
              nodeLabelComponent={nodeLabelComponent}
              allowForeignObjects={true}
              zoom={0.13}
              translate={translate}
              separation={{
                siblings: 4,
                nonSiblings: 3
              }}
              nodeSvgShapeCollapsedOverride={{
                shapeProps: {
                  stroke: "rgb(155,155,155)",
                  fill: "rgba(240,240,240)"
                }
              }}
            />
          )}
          <div className={styles.instructions}>
            <b>Tip:</b> Try zooming, panning and collapsing nodes
          </div>
        </div>
      </div>
    );
  }
}

export default AST;
