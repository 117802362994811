import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchSimilarNames,
  fetchCombinations,
  fetchAnalogies,
} from "../../redux/playground/actions";
import styles from "./WordGame.module.scss";

const mapDispatchToProps = dispatch => ({
  actions: {
    SIMILAR: {
      fetch: bindActionCreators(fetchSimilarNames, dispatch),
    },
    COMBINATION: {
      fetch: bindActionCreators(fetchCombinations, dispatch),
    },
    ANALOGY: {
      fetch: bindActionCreators(fetchAnalogies, dispatch),
    },
  },
});

const mapStateToProps = ({ playground }) => ({
  playground,
});

@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class WordGame extends React.Component {
  state = {
    inputs: [],
    outputs: [],
  };

  onKeyDown = e => {
    if (e.keyCode === 13) {
      // enter was clicked
      this.onEnter();
    }
  }

  onEnter() {
    this.compute();
  }

  constructor(props) {
    super(props);
    const {
      options: { numInputs, defaults },
    } = props;
    let inputs = [];
    for (let i = 0; i < numInputs; i++) {
      inputs.push(props => (
        <input
          ref={ref => {
            this[i] = ref;
          }}
          onKeyDown={this.onKeyDown}
          defaultValue={defaults[i]}
          {...props}
        />
      ));
    }
    this.state = { ...this.state, inputs };
  }

  compute = () => {
    const { type } = this.props.options;
    const inputs = this.state.inputs.map((_, i) => this[i].value);
    if (type !== "ANALOGY") {
      this.props.actions[this.props.options.type].fetch(inputs);
    } else {
      this.props.actions[this.props.options.type].fetch(
        [inputs[1], inputs[2]],
        [inputs[0]],
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState();
  }

  render() {
    const state = this.props.playground[this.props.options.type];
    return (
      <div className={styles.container}>
        {this.props.children({
          inputs: this.state.inputs,
          compute: this.compute,
          isComputing: state.isComputing,
          outputs: state.outputs || [],
        })}
      </div>
    );
  }
}

export default WordGame;
