import { css } from "styled-components/macro";

const sizes = {
  tablet: 768,
  mobile: 576
};

// Iterate through the sizes and create a media template
const media: { [s: string]: any } = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

media.desktop = (...args) => css`
  @media (min-width: ${sizes.tablet}px) {
    ${css(...args)};
  }
`;

export default media;
