import { opacify } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import * as COLORS from "../../styles/theme/colors";
import media from "../../styles/theme/media";

export interface Credit {
  title: string;
  subtitle: string;
  src: string;
}

const Container = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  ${media.tablet`
    flex-flow: column nowrap;
  `};
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: ${COLORS.PRIMARY_2};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 6px;
`;
const Subtitle = styled.a`
  color: ${COLORS.AUX};
  font-size: 12px;
  font-weight: 300;
`;

const CreditContainer = styled.li`
  position: relative;
  text-align: center;
  width: 250px;
  padding: 0 24px;
  color: red;
  ${media.tablet`
    padding-top: 48px;
    padding-bottom: 48px;
  `} ${media.desktop`
    &:nth-of-type(n+2)::before {
      content: '';
      position: absolute;
      left: 0;
      top: -8px;
      height: calc(100% + 16px);
      width: 1px;
      background: linear-gradient(0deg, ${opacify(
        -0.95,
        COLORS.AUX
      )} 0%, ${opacify(-0.7, COLORS.AUX)} 50%, ${opacify(
    -0.95,
    COLORS.AUX
  )} 100%);
    }
  `};
`;

const Credit = ({ title, subtitle, src }: Credit) => (
  <CreditContainer>
    <Title>{title}</Title>
    <Subtitle target="_blank" href={src}>
      {subtitle}
    </Subtitle>
  </CreditContainer>
);

const Credits: React.SFC<{ credits: Credit[]; className?: string }> = ({
  credits,
  className
}) => (
  <Container className={className || ""}>
    {credits.map(({ title, subtitle, src }) => (
      <Credit key={title} title={title} subtitle={subtitle} src={src} />
    ))}
  </Container>
);

export default Credits;
