// @flow

import * as TYPES from "./types";
import { List } from "immutable";

const LIMIT_MESSAGES = 5;

export type SystemMessageProperties = {
  type: "TOAST" | "MODAL";
  timestamp?: string | null;
  title?: string;
  message: string;
  active?: boolean;
};

export type State = {
  messages: List<SystemMessageProperties>;
};

const initialState = {
  messages: List<SystemMessageProperties>()
};

export default (
  state: State = initialState,
  action = { type: "", payload: null }
) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.ADD_SYSTEM_MESSAGE:
      let messages = state.messages.push({
        ...payload,
        timestamp: Date.now()
      });
      if (state.messages.size >= LIMIT_MESSAGES) {
        // overflow
        messages = messages.delete(0);
      }
      return {
        ...state,
        messages
      };
    case TYPES.REMOVE_SYSTEM_MESSAGE:
      const indexOfMsg = state.messages.findIndex(
        ({ timestamp: t }) => t === payload.timestamp
      );
      if (indexOfMsg === -1) return state;
      return {
        ...state,
        messages: state.messages.delete(indexOfMsg)
      };
    case TYPES.CLEAR_SYSTEM_MESSAGES:
      return initialState;
    default:
      return state;
  }
};
