import cx from "classnames";
import * as React from "react";
import AuthorCard from "./AuthorCard";
// @ts-ignore
import * as styles from "./AuthorsGrid.module.scss";

interface Props {
  className?: null | string;
}

const AuthorsGrid = ({ className = null, ...rest }: Props) => (
  <ul className={cx(styles.container, className)}>
    <AuthorCard
      title="Uri Alon"
      affiliation="Technion"
      //   imageSrc="https://user-images.githubusercontent.com/15002544/34647284-dba00aa6-f387-11e7-8e39-fab55609e28a.JPG"
      imageSrc="/images/authors_uri.jpg"
      imageStyle={{
        marginTop: "-10px"
      }}
      website="https://urialon.ml/"
    />
    <AuthorCard
      title="Meital Zilberstein"
      affiliation="Technion"
      imageSrc="/images/authors_meital.png"
      website="http://www.cs.technion.ac.il/~mbs/"
    />
    <AuthorCard
      title="Omer Levy"
      affiliation="Facebook AI Research"
      imageSrc="/images/authors_omer.jpg"
      website="https://levyomer.wordpress.com/"
      imageStyle={{
        width: "140%"
      }}
    />
    <AuthorCard
      title="Eran Yahav"
      affiliation="Technion"
      imageSrc="/images/authors_eran.jpg"
      website="http://www.cs.technion.ac.il/~yahave/"
    />
  </ul>
);

export default AuthorsGrid;
