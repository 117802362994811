import cx from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// @ts-ignore
import IconQuestion from "../../../assets/svg/iconmonstr-help-1.svg";
// @ts-ignore
import IconMouse from "../../../assets/svg/mouse.svg";
import Card from "../../components/Card";
import Editor from "../../components/Editor";
import HelpTip from "../../components/HelpTip";
import PredictionsList from "../../components/PredictionsList";
import PredictionsViz from "../../components/PredictionsViz";
import SpinningArrow from "../../components/SpinningArrow";
import { getRandomInt } from "../../lib/math-helpers";
import {
  getPredictions,
  resetEditorDirty,
  setEditorDirty
} from "../../redux/predictions/actions";
// @ts-ignore
import examplesJSON from "./examples.json";
import * as styles from "./Guesser.module.scss";
import PredictCTA from "./PredictCTA";

const examples = examplesJSON.examples;

interface ExampleProps {
  onClickRandomExample(e: any, i: any): void;
  onClickExample(index: number): any;
  lastPressedIndex: number; // index of last pressed example
}

// TODO: document
const Examples: React.SFC<ExampleProps> = ({
  onClickRandomExample,
  onClickExample,
  lastPressedIndex
}) => (
  <div className={styles.examplesContainer}>
    Examples:
    <ul className={styles.examples}>
      {examples.map(({ tooltip = null }, i) => (
        <li
          key={i}
          className={cx(
            styles.example,
            i === lastPressedIndex && styles.exampleLastPressed
          )}
          onClick={onClickExample(i)}
        >
          {i + 1}
        </li>
      ))}
      <li
        className={cx(
          styles.example,
          styles.exampleRandom,
          styles.iconQuestionContainer
        )}
        onClick={onClickRandomExample}
      >
        <IconQuestion className={styles.iconQuestion} />
        <div className={styles.exampleTooltip}>random</div>
      </li>
    </ul>
  </div>
);

const GuesserHelpTip = () => (
  <HelpTip>
    Input some Java code inside the editor and click on the arrow to generate
    predictions for the method's name. <br />
    You can use the examples provided below the editor (click on each one to
    replace editor's contents) or insert your own code.
  </HelpTip>
);

const mapStateToProps = ({ predictions }) => ({
  predictionNames: predictions.names,
  isGuessing: predictions.isGuessing,
  attentionPaths: predictions.attentionPaths,
  isEditorDirty: predictions.isEditorDirty
});
const mapDispatchToProps = (dispatch) => ({
  getPredictions: bindActionCreators(getPredictions, dispatch),
  setEditorDirty: bindActionCreators(setEditorDirty, dispatch),
  resetEditorDirty: bindActionCreators(resetEditorDirty, dispatch)
});

interface State {
  lastPressedExampleIndex: number;
}

interface Props {
  getPredictions?(any): any;
  isGuessing?: boolean;
  isScrolledToTop: boolean;
  predictionNames?: any; // TODO
}

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Guesser extends React.Component<Props, State> {
  public state = {
    lastPressedExampleIndex: 0
  };
  public guess = () => {
    if (this.props.isGuessing) return;
    const editor = this.editor;
    if (!editor || !editor.editor) return;
    const code = editor.editor.getValue() || null;
    this.props.getPredictions({ code });
    this.props.resetEditorDirty();
  }
  public changeEditorContentToExample = (exampleIndex: number) => () => {
    const editor = this.editor;
    if (!editor || !editor.editor) return;
    editor.editor.setValue(examples[exampleIndex].code || examples[0].code);
    editor.editor.selection.clearSelection();
    this.props.setEditorDirty();
    this.setState({
      lastPressedExampleIndex: exampleIndex
    });
  }

  public handleEditorFocus = (e) => {
    if (!this.props.isEditorDirty) {
      this.props.setEditorDirty();
    }
    this.setState({
      isEditorInFocus: true
    });
  }

  public handleEditorBlur = (e) => {
    // HACK for event propogation when clicking something else
    window.setTimeout(() => {
      this.setState({
        isEditorInFocus: false
      });
    }, 100);
  }

  public handleClickRandomExample = () => {
    const randomExampleIndex = getRandomInt(0, examples.length - 1);
    this.changeEditorContentToExample(randomExampleIndex)();
  }

  public componentDidMount() {
    this.changeEditorContentToExample(0)();
  }

  public render() {
    const { isEditorInFocus, lastPressedExampleIndex } = this.state;
    const { isScrolledToTop } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.helpTip}>
          <GuesserHelpTip />
        </div>
        <div className={styles.container}>
          <div
            className={cx(styles.editorWrapper, {
              [styles.editorWrapperFocused]: isEditorInFocus
            })}
          >
            <div className={styles.editorWrapperInner}>
              <Card sideTitle="JAVA">
                <Editor
                  innerRef={(ref) => {
                    this.editor = ref;
                  }}
                  onChange={this.handleEditorChange}
                  onFocus={this.handleEditorFocus}
                  onBlur={this.handleEditorBlur}
                  className={styles.editor}
                  defaultValue={examples[0].code}
                />
                <PredictionsList
                  shouldShowGhost={false}
                  isPredicting={this.props.isGuessing}
                  isEditorDirty={this.props.isEditorDirty}
                  predictions={this.props.predictionNames}
                  className={cx(styles.predictionsList, {
                    [styles.predictionsListBlurred]: this.props.isEditorDirty
                  })}
                />
              </Card>
            </div>
            <Examples
              onClickRandomExample={this.handleClickRandomExample}
              onClickExample={this.changeEditorContentToExample}
              lastPressedIndex={lastPressedExampleIndex}
            />
          </div>
          <div className={styles.ctaContainer}>
            <PredictCTA
              // className={styles.spinningArrow}
              isPredicting={this.props.isGuessing}
              onClick={this.guess}
            />
          </div>
          <div className={styles.predictionsContainer}>
            <PredictionsViz
              isPredicting={this.props.isGuessing}
              predictions={this.props.predictionNames}
            />
          </div>
        </div>
        <div
          className={cx(styles.iconMouseContainer, {
            [styles.iconMouseContainerScrolledTop]: isScrolledToTop
          })}
        >
          <IconMouse className={styles.iconMouse} />
        </div>
      </div>
    );
  }
}

export default Guesser;
