import * as TYPES from "./types";
import { getSimilarNames, getCombinations, getAnalogies } from "../../lib/api";

export function fetchSimilarNames(inputs: string[]) {
  return dispatch => {
    dispatch({
      type: TYPES.GET_EMBEDDINGS_START,
      payload: { type: TYPES.SIMILAR }
    });
    getSimilarNames(inputs).then(outputs => {
      dispatch({
        type: TYPES.GET_EMBEDDINGS_SUCCESS,
        payload: {
          type: TYPES.SIMILAR,
          outputs
        }
      });
    });
  };
}

export function fetchCombinations(inputs: string[]) {
  return dispatch => {
    dispatch({
      type: TYPES.GET_EMBEDDINGS_START,
      payload: { type: TYPES.COMBINATION }
    });
    getCombinations(inputs).then(outputs => {
      dispatch({
        type: TYPES.GET_EMBEDDINGS_SUCCESS,
        payload: {
          type: TYPES.COMBINATION,
          outputs
        }
      });
    });
  };
}

export function fetchAnalogies(first: string[], second: string[]) {
  return dispatch => {
    dispatch({
      type: TYPES.GET_EMBEDDINGS_START,
      payload: { type: TYPES.ANALOGY }
    });
    getAnalogies(first, second).then(outputs => {
      dispatch({
        type: TYPES.GET_EMBEDDINGS_SUCCESS,
        payload: {
          type: TYPES.ANALOGY,
          outputs
        }
      });
    });
  };
}
