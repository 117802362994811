import cx from "classnames";
import * as React from "react";
import { Motion, spring } from "react-motion";
import SVG from "../../../assets/svg/iconmonstr-redo-7.svg";
import styles from "./SpinningArrow.module.scss";

interface Props {
  shouldSpin?: boolean;
  className?: string;
}

class SpinningArrow extends React.Component<Props> {
  public static defaultProps = {
    shouldSpin: true,
    className: null
  };

  public render() {
    const { shouldSpin, className, ...rest } = this.props;
    return (
      <Motion
        defaultStyle={{ s: 0 }}
        style={{ s: shouldSpin ? spring(1) : spring(0) }}
      >
        {({ s }) => (
          <div
            className={styles.container}
            style={{
              transform: `rotate(${shouldSpin ? s * 90 : 90 + (1 - s) * 270}deg`
            }}
          >
            <SVG
              className={cx(
                styles.spinningArrow,
                {
                  [styles.spinningArrowSpin]: shouldSpin
                },
                className
              )}
              {...rest}
            />
          </div>
        )}
      </Motion>
    );
  }
}

export default SpinningArrow;
