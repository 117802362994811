import * as TYPES from "./types";
import { SystemMessageProperties } from "./reducer";

const noop = () => {};

export const MESSAGE_DELAY = 30000;

export function addSystemMessage(props: SystemMessageProperties) {
  return dispatch => {
    const timestamp = Date.now();
    dispatch({
      type: TYPES.ADD_SYSTEM_MESSAGE,
      payload: { ...props, timestamp }
    });
    setTimeout(() => {
      removeSystemMessage(timestamp)(dispatch);
    }, MESSAGE_DELAY);
  };
}

export function removeSystemMessage(timestamp: number) {
  return dispatch => {
    dispatch({
      type: TYPES.REMOVE_SYSTEM_MESSAGE,
      payload: { timestamp }
    });
  };
}

export function clearSystemMessages() {
  return {
    type: TYPES.CLEAR_SYSTEM_MESSAGES
  };
}
