import cx from "classnames";
import * as React from "react";
import * as styles from "./Section.module.scss";

interface SectionProps {
  title?: string | null;
  HelpTip?: React.SFC<{}>;
  className?: string;
  children: JSX.Element | JSX.Element[] | null;
}
const Section = ({
  title = null,
  HelpTip = null,
  className = null,
  children = null
}: SectionProps) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.titleContainer}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {HelpTip && <HelpTip />}
    </div>
    {children}
  </div>
);

export default Section;
